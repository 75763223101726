<template>
    <transition name="fade">
      <div>
        <Hero alt="Hall-8" heading="6.8 Spuizaal" />
        <Gallery
          :type="6"
          :slides="gallery"
          heading="6.8 Spuizaal"
          info="De Spuizaal is een kleine, maar fijne ruimte op de 6e etage.<br><br>"
          :area="space.area"
          :persons="space.persons"
          :options="space.options"
          :prices="space.prices"
          brochure="true"
        />
        <Form id="contact" />
        <Footer />
      </div>
    </transition>
  </template>
  
  <script>
  import Hero from "@/components/Hero.vue";
  import Gallery from "@/components/Gallery.vue";
  import Threed from "@/components/Threed.vue";
  import Form from "@/components/Form.vue";
  import Footer from "@/components/Footer.vue";
  
  import { spacesInfo } from "@/data/spaces.js";
  
  import AOS from "aos";
  
  export default {
    name: "Hall8",
  
    components: {
      Hero,
      Gallery,
      Threed,
      Form,
      Footer
    },
  
    data() {
      return {
        gallery: [
          { delay: 0, bg: "Hall-8-1" },
          { delay: 100, bg: "Hall-8-2" },
          { delay: 200, bg: "Hall-8-3" },
          { delay: 300, bg: "Hall-8-4" },
          { delay: 400, bg: "Hall-8-5" },
          { delay: 500, bg: "Hall-8-6" }
        ]
      };
    },
  
    computed: {
      space() {
        return spacesInfo.find(
          space => space.url.name === this.$options.name || space.url.name.replace("-", "") === this.$options.name
        );
      }
    },
  
    mounted() {
      window.scrollTo(0, 0);
  
      AOS.init({
        offset: 0,
        duration: 600,
        anchorPlacement: "top-bottom",
        easing: "cubic-bezier(0.77, 0, 0.175, 1)"
      });
    }
  };
  </script>
  