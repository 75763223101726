import { render, staticRenderFns } from "./Hall-6.vue?vue&type=template&id=dcfe5428"
import script from "./Hall-6.vue?vue&type=script&lang=js"
export * from "./Hall-6.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports