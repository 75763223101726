<template>
    <transition name="fade">
      <div>
        <Hero alt="Hall-4.2" heading="4.2 vergaderzaal" />
        <Gallery
          :type="3"
          :slides="gallery"
          heading="4.2 vergaderzaal"
          info="Deze vergaderzaal ligt in de noordoosthoek van het gebouw.<br><br>"
          :area="space.area"
          :persons="space.persons"
          :options="space.options"
          :prices="space.prices"
          brochure="true"
        />
        <Form id="contact" />
        <Footer />
      </div>
    </transition>
  </template>
  
  <script>
  import Hero from "@/components/Hero.vue";
  import Gallery from "@/components/Gallery.vue";
  import Form from "@/components/Form.vue";
  import Footer from "@/components/Footer.vue";
  
  import { spacesInfo } from "@/data/spaces.js";
  
  import AOS from "aos";
  
  export default {
    name: "Hall4_2",
  
    components: {
      Hero,
      Gallery,
      Form,
      Footer
    },
  
    data() {
      return {
        gallery: [
          { delay: 0, bg: "Hall-4_2-1" },
          { delay: 100, bg: "Hall-4_2-2" },
          { delay: 200, bg: "Hall-4_2-3" },
        ]
      };
    },
  
    computed: {
      space() {
        return spacesInfo.find(
          space => space.url.name === this.$options.name || space.url.name.replace("-", "") === this.$options.name
        );
      }
    },
  
    mounted() {
      window.scrollTo(0, 0);
  
      AOS.init({
        offset: 0,
        duration: 600,
        anchorPlacement: "top-bottom",
        easing: "cubic-bezier(0.77, 0, 0.175, 1)"
      });
    }
  };
  </script>
  