<template>
  <transition name="fade">
    <div>
      <Hero alt="forum" heading="OBA Forum" /><br />
      <br />
      <Gallery
        :type="5"
        :slides="gallery"
        heading="OBA Forum"
        info="De Forumzaal is een hoge ruimte met een uitschuifbare tribune en een balkon, die beide perfect zicht bieden op het grote scherm.<br><br>"
        :area="space.area"
        :persons="space.persons"
        :options="space.options"
        :prices="space.prices"
        brochure="true"
      />
      <Threed url="https://my.matterport.com/show/?m=woPwxWoL6d3" bg="forum" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Threed from "@/components/Threed.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Forum",

  components: {
    Hero,
    Gallery,
    Threed,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Forum-1" },
        { delay: 100, bg: "Forum-2" },
        { delay: 200, bg: "Forum-3" },
        { delay: 300, bg: "Forum-4" },
        { delay: 400, bg: "Forum-5" },
      ]
    };
  },

  computed: {
    space() {
      return spacesInfo.find(space => space.url.name === this.$options.name);
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
