<template>
  <router-link :to="{ name: 'HomeOverview' }">
    <div ref="logo" class="logo"></div>
  </router-link>
</template>

<script>
export default {
  name: "Logo",

  props: ["type"],

  mounted() {
    if (this.type === "large") this.$refs.logo.classList.add("logo--large");
    if (this.type === "xl") this.$refs.logo.classList.add("logo--xl");
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

$logo-ratio: 0.2045;

.logo {
  @include size-by-width(130, $logo-ratio);
  background-image: url("../assets/images/oba-congres-logo-black-green.svg");
  background-repeat: no-repeat;
  background-size: contain;

  &--large {
    @include size-by-width(152, $logo-ratio);
  }

  &--xl {
    @include size-by-width(184, $logo-ratio);
  }

  @include respond-to("s") {
    @include size-by-width(200, $logo-ratio);

    &--large {
      @include size-by-width(168, $logo-ratio);
    }

    &--xl {
      @include size-by-width(368, $logo-ratio);
    }
  }
}
</style>
