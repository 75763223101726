<template>
  <transition name="fade">
    <div>
      <Hero alt="Hall-3" heading="6.3 Singelzaal" />
      <Gallery
        :type="4"
        :slides="gallery"
        heading="6.3 Singelzaal"
        info="De Singelzaal leent zich uitstekend voor bijeenkomsten en presentaties.<br><br>
        Behoefte aan meer ruimte? De Singelzaal kunnen wij moeiteloos samenvoegen met de Herenzaal. Zo wordt de ideale setting gecreëerd voor grotere bijeenkomsten en presentaties.<br><br>"
        :area="space.area"
        :persons="space.persons"
        :options="space.options"
        :prices="space.prices"
        brochure="true"
      />
      <Threed
        heading="3D/360º Walkthroughs"
        info="Om een betere indruk te krijgen van onze aanbod kunt u een virtuele 3D tour doen. Bekijk de zaal in 360º of van bovenaf als plattegrond."
        url="https://my.matterport.com/show/?m=QbrwbiQ3EgG"
        combi-url="https://my.matterport.com/show/?m=wfZM82CQRUH"
        bg="hall-3"
      />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Threed from "@/components/Threed.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Hall3",

  components: {
    Hero,
    Gallery,
    Threed,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Hall-3-1" },
        { delay: 100, bg: "Hall-3-2" },
        { delay: 200, bg: "Hall-3-3" },
        { delay: 300, bg: "Hall-3-4" }
      ]
    };
  },

  computed: {
    space() {
      return spacesInfo.find(
        space => space.url.name === this.$options.name || space.url.name.replace("-", "") === this.$options.name
      );
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
